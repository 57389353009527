<style lang="scss">
  @import './VolumeSlider.scss';
</style>
<template>
  <div class="volume-slider-container">
    <i :class="iconClass" v-on:click="onMuteUnMute"></i>
    <input type="range" :step="stepValue" :min="minValue" :max="maxValue" v-model="volumeValue" v-on:input="onChangeVolume($event)"/>
  </div>
</template>
<script>
import { CHANGE_VOLUME } from '../../store/mutation-types'
export default {
  name: 'VolumeSlider',
  props: {
    volume: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      volumeValue: this.volume,
      minValue: this.min,
      maxValue: this.max,
      stepValue: this.step,
      previousVolume: this.volume
    }
  },
  computed: {
    iconClass() {
      if (this.volume === 0) {
        return 'icon-volume-off'
      }

      if (this.volume <= 40) {
        return 'icon-volume-down'
      }

      if (this.volume <= 80) {
        return 'icon-volume'
      }

      return 'icon-volume-up'
    }
  },
  methods: {
    onChangeVolume () {
      const volume = parseInt(this.volumeValue, 10)
      this.$store.commit(CHANGE_VOLUME, volume)
      this.$emit(CHANGE_VOLUME, volume)
    },
    onMuteUnMute () {
      if (this.volumeValue > 0) {
        this.previousVolume = parseInt(this.volumeValue, 10)
        this.volumeValue = 0
        this.$store.commit(CHANGE_VOLUME, 0)
      } else {
        this.volumeValue = this.previousVolume
        this.$store.commit(CHANGE_VOLUME, this.previousVolume)
      }
    }
  }
}
</script>
