import { ADV_RELOAD_TIME } from '../../lib/constants'
import { UPDATE_INFO_SKIN, UPDATE_SHOW_SKIN, TOGGLE_SHOW_PREROLL, TOGGLE_SHOW_ADVERTISING, RESET_TO_DEFAULT } from '../mutation-types'

const { adBlockEnabled = false } = window;

export const initialState = {
  showAdvertising: process.env.VUE_APP_ADVERTISING_ENABLED === '1',
  showPreroll: true,
  showSkin: true,
  infoSkin: {},
  adBlockEnabled
}

const advertisingModule = {
  state: () => ({ ...initialState, infoSkin: { ...initialState.infoSkin } }),
  mutations: {
    // Advertising
    [UPDATE_INFO_SKIN] (state, infoSkin) {
      state.infoSkin = infoSkin
    },
    [UPDATE_SHOW_SKIN] (state, showSkin) {
      const newValue = showSkin || !state.showSkin
      state.showSkin = newValue
    },
    [TOGGLE_SHOW_PREROLL] (state, showPreroll) {
      state.showPreroll = showPreroll
      setTimeout(() => { state.showPreroll = true }, ADV_RELOAD_TIME.PREROLL)
    },
    [TOGGLE_SHOW_ADVERTISING] (state, showAdvertising) {
      state.showAdvertising = showAdvertising
    }
  },
  actions: {
    [RESET_TO_DEFAULT] ({ commit }) {
      commit(UPDATE_INFO_SKIN, {})
      commit(UPDATE_SHOW_SKIN, true)
      commit(TOGGLE_SHOW_PREROLL, true)
    }
  }
}

export default advertisingModule
