<style lang="scss" scoped>
    @import './OnAirInfoBoxShow.scss';
</style>
<template>
  <div v-if="showBox" v-bind:class="containerClass">
    <div class="onair-info-box-show-overlay">
      <div class="image-container">
        <img :src="imageSrc" :alt="programName" :style="{ backgroundImage: 'url(' + image + ')' }" />
        <!--<div class="image-overlay"></div>-->
      </div>
      <div class="info-container">
        <div class="first-line">{{programName}}</div>
        <div class="second-line">{{speakers}}</div>
        <div class="third-line" v-if="infos !== null">{{infos}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OnAirInfoBoxShow',
  props: {
    show: {
      type: Object,
      default: null
    },
    media: {
      type: Object,
      default: null
    }
  },

  computed: {
    containerClass () {
      return { 'onair-info-box-show': true, 'wide-image': this.infoFromMedia }
    },
    infoFromMedia () {
      return this.show === null && this.media !== null
    },
    imageSrc () {
      return this.infoFromMedia
        ? 'data:image/gif;base64,R0lGODlhEAAJAIAAAP///wAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNy4xLWMwMDAgNzkuZGFiYWNiYiwgMjAyMS8wNC8xNC0wMDozOTo0NCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjAgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjQ1NDFDNkMwOUJBMzExRUNCQTFGOTI5NjM5OEY2QTU5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjQ1NDFDNkMxOUJBMzExRUNCQTFGOTI5NjM5OEY2QTU5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NDU0MUM2QkU5QkEzMTFFQ0JBMUY5Mjk2Mzk4RjZBNTkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NDU0MUM2QkY5QkEzMTFFQ0JBMUY5Mjk2Mzk4RjZBNTkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQBAAAAACwAAAAAEAAJAAACCoSPqcvtD6OclBUAOw=='
        : 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
    },
    mediaTitle () {
      const { mediaMetadata: { title = null }, broadcasterName } = this.media

      if (title === null) {
        return broadcasterName
      }

      return ((title.match(/-/g) || []).length > 0)
        ? title.split('-')[0].trim()
        : title
    },
    mediaSubtitle () {
      const { mediaMetadata: { title = null, subtitle = null } } = this.media

      return ((title.match(/-/g) || []).length >= 1)
        ? title.split('-').slice(1).join('-').trim()
        : subtitle
    },
    showBox () {
      return this.show !== null || this.media !== null
    },
    programName () {
      return (this.show !== null) ? this.show.program : this.mediaTitle
    },
    speakers () {
      return (this.show !== null) ? `Con ${this.show.speakers}` : this.mediaSubtitle
    },
    infos () {
      const { mediaMetadata: { title = null, subtitle = null } } = this.media

      return ((title.match(/-/g) || []).length > 0)
        ? subtitle
        : null
    },
    image () {
      const { image = null } = this.show || {}
      const { poster = null } = this.media.mediaMetadata || {}

      if (image !== null) {
        return image
      }

      if (poster !== null) {
        return poster
      }

      return 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
    }
  }
}
</script>
