<style lang="scss" scoped>
    @import './PlayerBar.scss';
</style>
<template>
  <div v-bind:class="containerClass">
    <PlayerBarCloseButton />
    <PlayerBarOnAirInfo />
    <PlayerControls />
    <VolumeSlider :volume="volume"/>
    <ChangeStreamTypeButton />
  </div>
</template>
<script>
import PlayerBarCloseButton from '../PlayerBarCloseButton/PlayerBarCloseButton'
import VolumeSlider from '../VolumeSlider/VolumeSlider'
import PlayerBarOnAirInfo from '../PlayerBarOnAirInfo/PlayerBarOnAirInfo'
import PlayerControls from '../PlayerControls/PlayerControls'
import ChangeStreamTypeButton from '../ChangeStreamTypeButton/ChangeStreamTypeButton'
export default {
  name: 'PlayerBar',
  components: {
    PlayerBarCloseButton,
    VolumeSlider,
    PlayerBarOnAirInfo,
    PlayerControls,
    ChangeStreamTypeButton
  },
  computed: {
    volume () { return this.$store.state.playoutInfo.volume },
    containerClass () {
      return { 'player-bar': true, 'preroll-is-playing': this.$store.state.layout.isPrerollPlaying }
    }
  }
}
</script>
