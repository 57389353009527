<style lang="scss">
    @import './PlayerBarCloseButton.scss';
</style>
<template>
  <div class="player-bar-close-button" v-on:click="close"><i class="icon-close"></i></div>
</template>
<script>
import { UPDATE_CURRENT_MEDIA, TOGGLE_SHOW, TOGGLE_FULLSCREEN } from '../../store/mutation-types'
import { exitFullscreenMode } from '../../lib/utility'
export default {
  name: 'PlayerBarCloseButton',
  computed: {
    isPrerollPlaying () { return this.$store.state.layout.isPrerollPlaying },
    isFullscreen () { return this.$store.state.playoutInfo.isFullscreen }
  },
  methods: {
    close () {
      this.$store.commit(TOGGLE_SHOW, false)
      this.$store.commit(UPDATE_CURRENT_MEDIA, null)
      if (this.isFullscreen) {
        exitFullscreenMode(document.body)
        this.$store.commit(TOGGLE_FULLSCREEN, false)
      }
    }
  }
}
</script>
