// STORE
export const RESET_TO_DEFAULT = 'RESET_TO_DEFAULT'

// MEDIA INFO
export const UPDATE_CURRENT_MEDIA = 'UPDATE_CURRENT_MEDIA'
export const UPDATE_RELATED_MEDIA = 'UPDATE_RELATED_MEDIA'
export const SWITCH_MEDIA = 'SWITCH_MEDIA'
export const UPDATE_NIELSEN_VIDEO_APP_ID = 'UPDATE_NIELSEN_VIDEO_APP_ID'
// export const UPDATE_ADV_TAG = 'UPDATE_ADV_TAG'

// ADVERTISING
export const UPDATE_INFO_SKIN = 'UPDATE_INFO_SKIN'
export const UPDATE_SHOW_SKIN = 'UPDATE_SHOW_SKIN'
export const TOGGLE_SHOW_PREROLL = 'TOGGLE_SHOW_PREROLL'
export const TOGGLE_SHOW_ADVERTISING = 'TOGGLE_SHOW_ADVERTISING'

// LAYOUT
export const TOGGLE_THRON_IS_INITIALIZED = 'TOGGLE_THRON_IS_INITIALIZED'
export const TOGGLE_LAYOUT_PLAY_PAUSE = 'TOGGLE_LAYOUT_PLAY_PAUSE'
export const TOGGLE_LAYOUT_FULLSCREEN = 'TOGGLE_LAYOUT_FULLSCREEN'
export const TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX = 'TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX'
export const UPDATE_LAYOUT_CAN_SEND_NOTIFICATION = 'UPDATE_LAYOUT_CAN_SEND_NOTIFICATION'
export const TOGGLE_PREROLL_IS_PLAYING = 'TOGGLE_PREROLL_IS_PLAYING'
export const TOGGLE_SHOW = 'TOGGLE_SHOW'
export const FIREBASE_AUTH_START = 'FIREBASE_AUTH_START'
export const FIREBASE_AUTH_SUCCESS = 'FIREBASE_AUTH_SUCCESS'
export const FIREBASE_AUTH_FAILED = 'FIREBASE_AUTH_FAILED'

// PLAYOUT INFO
export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN'
export const CHANGE_VOLUME = 'CHANGE_VOLUME'
export const CHANGE_PLAYOUT_IS_PLAYING = 'CHANGE_PLAYOUT_IS_PLAYING'
export const UPDATE_PLAYOUT_ONAIR_INFO = 'UPDATE_PLAYOUT_ONAIR_INFO'
