<style lang="scss" scoped>
  @import './DFPContainer.scss';
</style>
<template>
  <div class="dfp-container">
    <div v-for="zone in zones" :id="zone.domId" :key="zone.domId" :style="{ width: zone.unitSize[0] + 'px', height: zone.unitSize[1] + 'px' }"></div>
  </div>
</template>
<script>
import { DFP_ZONES } from '../../lib/constants'
export default {
  name: 'DFPContainer',
  data: function () {
    return { zones: DFP_ZONES }
  }
}
</script>
