/* eslint-disable */
// import axios from 'axios';
import Firebase from './firebase';
import { set, push, update, onDisconnect, serverTimestamp } from 'firebase/database'
import UserAgentInfo from './userAgentInfo';
import { MEDIA_SOURCE } from './constants'

class ClientTracker {
    constructor(){
      this.activeSession  = null;
      this.ipAddress      = "0.0.0.0";

    }

    getSessionDefaultValue(){
      return {
        start   : serverTimestamp(),
        end     : serverTimestamp(),
        ip      : this.ipAddress,
        source  : 'WEB',
        os      : UserAgentInfo.os,
        device  : {
            model   : UserAgentInfo.device.model || null,
            vendor  : UserAgentInfo.device.vendor || null,
            type    : UserAgentInfo.device.type || null
        },
        client  : UserAgentInfo.browser
      }
    }

    clearCurrentSession = async () => {
      if (this.activeSession !== null){
        // clear update on disconnect
        await onDisconnect(this.activeSession).cancel();
        
        // update endTime        
        await update(this.activeSession, { end : serverTimestamp() })
        
        // clear activeSession
        this.activeSession = null;
      }
    }

    trackMediaInfoChange = async (mediaInfo = null) => {

      this.clearCurrentSession();

      if (mediaInfo !== null){
        const { type, title, id, source } = mediaInfo;

        if (source === MEDIA_SOURCE.LIVE){
          // generate new session
          this.activeSession = push(Firebase.getUserDBRef());

          set(this.activeSession, {
              ...this.getSessionDefaultValue(),
              id,
              type : type - 200,
              label : title
          })
          .then(() => {
            onDisconnect(this.activeSession)
              .update({ end : serverTimestamp() })
          })
          .catch((error) => { 
            this.activeSession = null; 
          });
        }
      }
    }
}

export default new ClientTracker();
