import { TOGGLE_FULLSCREEN, CHANGE_VOLUME, CHANGE_PLAYOUT_IS_PLAYING, UPDATE_PLAYOUT_ONAIR_INFO, RESET_TO_DEFAULT } from '../mutation-types'

export const initialState = {
  volume: 100,
  isPlaying: false,
  isFullscreen: false,
  onAirInfo: null
}

const playoutInfoModule = {
  state: () => ({ ...initialState }),
  mutations: {
    [TOGGLE_FULLSCREEN] (state, newFullscreenState) {
      state.isFullscreen = newFullscreenState
    },
    [CHANGE_VOLUME] (state, newVolume) {
      state.volume = newVolume
    },
    [CHANGE_PLAYOUT_IS_PLAYING] (state, newPlayState) {
      state.isPlaying = newPlayState
    },
    [UPDATE_PLAYOUT_ONAIR_INFO] (state, onAirInfo) {
      state.onAirInfo = onAirInfo
    }
  },
  actions: {
    [RESET_TO_DEFAULT] ({ commit }) {
      commit(TOGGLE_FULLSCREEN, false)
      commit(CHANGE_VOLUME, 100)
      commit(UPDATE_PLAYOUT_ONAIR_INFO, null)
    }
  }
}

export default playoutInfoModule
