import { FIREBASE_AUTH_SUCCESS, FIREBASE_AUTH_FAILED } from '../mutation-types'

export const initialState = {
  uid: null,
  isAnonymous: false,
  displayName: null,
  email: null,
  emailVerified: false,
  phoneNumber: null,
  photoURL: null,
  isNewUser: false,
  providerId: null
}

const userInfoModule = {
  state: () => initialState,
  mutations: {
    [FIREBASE_AUTH_SUCCESS] (state, { uid, isAnonymous, displayName, email, emailVerified, phoneNumber, photoURL, isNewUser, providerId }) {
      state = { ...state, uid, isAnonymous, displayName, email, emailVerified, phoneNumber, photoURL, isNewUser, providerId }
    },
    [FIREBASE_AUTH_FAILED] (state) {
      state.uid = null
      state.isAnonymous = false
    }
  }
}

export default userInfoModule
