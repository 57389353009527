<style lang="scss" scoped>
  @import './PlayerBarOnAirInfo.scss';
</style>
<template>
  <div class="player-bar-onair-info">
    <div v-if="firstLine !== null" class="first-line"><i class="icon-fiber-manual-record"></i> {{firstLine}}</div>
    <div v-if="secondLine !== null" class="second-line">{{secondLine}}</div>
  </div>
</template>
<script>
import { MEDIA_TYPE } from '../../lib/constants'
import { updateMediaSession } from '../../lib/utility'

export default {
  name: 'PlayerBarOnAirInfo',
  mounted () {
    updateMediaSession({
      title: this.firstLine,
      artist: this.secondLine,
      album: '',
      image: this.broadcasterLogo
    })
  },
  computed: {
    firstLine () {
      return (this.hasShow) ? 'Ora in onda' : `Stai ${this.currentMedia.type === MEDIA_TYPE.AUDIO ? 'ascoltando' : 'guardando'}`
    },
    hasShow () {
      return (this.onAirInfo !== null && this.onAirInfo.show !== null)
    },
    secondLine () {
      return (this.hasShow)
        ? this.onAirInfo.show.program
        : this.currentMedia.mediaMetadata.title
    },
    onAirInfo () {
      return this.$store.state.playoutInfo.onAirInfo
    },
    currentMedia () {
      return this.$store.state.mediaInfo.currentMedia
    },
    broadcasterLogo () {
      return (this.currentMedia.broadcasterImage) ? this.currentMedia.broadcasterImage.icon.png[300] : ''
    }
  }
}
</script>
