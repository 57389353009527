import { initializeApp } from 'firebase/app'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDatabase, ref, child } from 'firebase/database'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
}

class Firebase {
  constructor () {

    this.firebaseApp = initializeApp(config)

    this.auth = getAuth(this.firebaseApp)
    this.database = getDatabase(this.firebaseApp)
  }

  // Auth
  doSignInAnonymously = () => signInAnonymously(this.auth)

  // Clients ref
  getClientsDBRef = () => {
    const dbRef = ref(this.database)

    return child(dbRef, `${process.env.VUE_APP_FIREBASE_DATABASE_ROOT}/clients`)
  } 

  // User DBRef
  getUserDBRef = (userId = null) => {
    const { uid } = this.auth.currentUser;
    
    return child(this.getClientsDBRef(), userId === null ? uid : userId)
  }
}

export default new Firebase()
