<style lang="scss" scoped>
  @import './SkinContainer.scss';
</style>
<template>
  <div class="skin-container" v-bind:class="{ 'is-skin': isSkin, 'is-masthead': isMasthead }">
    <div v-if="display" class="skin-container-inner">
      <a :href="link" target="_blank" rel="nofollow">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" v-bind:style="{ backgroundImage: `url(${image})`}" alt="Skin" />
      </a>
      <div class="skin-tracking" v-html="content"></div>
    </div>
    <div class="notification-tracking"></div>
  </div>
</template>
<script>
export default {
  name: 'SkinContainer',

  computed: {
    display () { return this.content && this.image && this.link },
    content () {
      const {content = false } = this.$store.state.advertising.infoSkin;
      return content;
    },
    image () {
      const {image = false } = this.$store.state.advertising.infoSkin;
      return image;
    },
    link () {
      const {link = false } = this.$store.state.advertising.infoSkin;
      return link;
    },
    isSkin () {
      const {size = {} } = this.$store.state.advertising.infoSkin;
      return size.width / size.height < 2;
    },
    isMasthead () {
      const {size = {} } = this.$store.state.advertising.infoSkin;
      return size.width / size.height >= 2;
    },
  }
}
</script>
