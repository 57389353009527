<style lang="scss" scoped>
  @import './YoutubePlayer.scss';
</style>
<template>
  <div class="youtube-wrapper">
    <div id="player" ref="player"></div>
  </div>
</template>
<script>
import { getYoutubeVideoId, loadSkinFromADServer, enterFullscreenMode } from '../../lib/utility'
import { UPDATE_CURRENT_MEDIA, TOGGLE_PREROLL_IS_PLAYING, TOGGLE_SHOW, CHANGE_PLAYOUT_IS_PLAYING } from '../../store/mutation-types'

const defaultParams = {
  height: '100%',
  width: '100%',
  playerVars: {
    rel: 0,
    showinfo: 0,
    modestbranding: 1
  }
}

export default {
  name: 'YoutubePlayer',
  props: {
    currentMedia: {
      type: Object,
      default: null
    }
  },

  computed: {
    fullscreen () { return this.$store.state.layout.toggleFullscreen },
    changeState () { return this.$store.state.layout.togglePlayPause },
    volume () { return this.$store.state.playoutInfo.volume }
  },

  created: function () {
    this.domElementId = 'player'
    this.player = null
  },

  mounted: function () {
    const videoId = getYoutubeVideoId(this.currentMedia.uri)
    const params = Object.assign({}, defaultParams, { videoId })
    this.player = new window.YT.Player(this.domElementId, params)
    loadSkinFromADServer()

    this.player
      .addEventListener('onReady', () => {
        this.togglePrerollPlaying(false)
        this.player.playVideo()
      })

    this.player
      .addEventListener('onStateChange', (evt) => {
        switch (evt.data) {
          case window.YT.PlayerState.PLAYING:
            this.$store.commit(CHANGE_PLAYOUT_IS_PLAYING, true)
            break
          case window.YT.PlayerState.PAUSED:
            this.$store.commit(CHANGE_PLAYOUT_IS_PLAYING, false)
            break
          case window.YT.PlayerState.ENDED:
            this.$store.commit(CHANGE_PLAYOUT_IS_PLAYING, false)
            this.$store.commit(TOGGLE_SHOW, false)
            this.$store.commit(UPDATE_CURRENT_MEDIA, null)
            break
          default:
            break
        }
      })
  },

  watch: {
    fullscreen (newValue, oldValue) {
      if (newValue !== oldValue) {
        enterFullscreenMode(this.player.getIframe())
      }
    },
    changeState (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.togglePlay(newValue)
      }
    },
    volume (newValue) {
      this.player.setVolume(newValue)
    }
  },

  methods: {
    togglePlay () {
      const currentState = this.player.getPlayerState()
      if (currentState === 2) {
        this.player.playVideo()
      } else {
        this.player.pauseVideo()
      }
    },
    togglePrerollPlaying (isPlaying) {
      this.$store.commit(TOGGLE_PREROLL_IS_PLAYING, isPlaying)
    }
  },

  beforeDestroy: function () {
    if (this.player !== null) {
      this.player.destroy()
    }
  }
}
</script>
