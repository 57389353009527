/* eslint-disable */
import { ADV_RELOAD_TIME, EQUATIV_ZONES, /*DFP_DOM_ELEMENTS_ID,*/ MEDIA_TYPE, PAGE_ORIENTATION, BANNER_SIZE, MEDIA_SOURCE, MEDIA_CHANNEL } from './constants'
import { UPDATE_INFO_SKIN, UPDATE_SHOW_SKIN, TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX, UPDATE_PLAYOUT_ONAIR_INFO, FIREBASE_AUTH_SUCCESS, FIREBASE_AUTH_START, FIREBASE_AUTH_FAILED } from '../store/mutation-types'
// import googleTagManager from './googleTagManager'
import NotificationManager from './notificationManager'
import Firebase from './firebase'
import store from '../store/index'

/**
 * Delay for metadata change animation
 */
const delay = 0
let initialDelay = 0
let latestFingerPrint = null
const animationDuration = 1000
let loadSkinFromDFPTimeout = null
let loadSkinFromAdServerTimeout = null
let nielsenInstance = null;

export const getYoutubeVideoId = (youtubeVideoUrl) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = youtubeVideoUrl.match(regExp)
  return (match && match[7].length === 11) ? match[7] : false
}

export const parseMediaSource = ({ uri, channel, source, descriptor }) => {
  if (source === MEDIA_SOURCE.LIVE)
    return Promise.resolve(parseLiveDescriptor(descriptor))

  switch (channel) {
    case MEDIA_CHANNEL.MAINSTREAMING:
      return Promise.resolve(parseMainstreamingDescriptor(descriptor))
    /*case MEDIA_CHANNEL.THRON:
      return Promise.resolve(parseTHRONDescriptor(descriptor))*/
    case MEDIA_CHANNEL.WEB:
      return Promise.resolve({ src: uri, type: 'video/mp4' })
  }
}

export const parseLiveDescriptor = (descriptor) => {
  const rendition = descriptor.filter(({ type }) => type === 'HLS')
  return { src : rendition[0].uri, type: 'application/x-mpegURL', hls: { useLegacy: true } }
}

export const parseMainstreamingDescriptor = (descriptor) => {
  const { endpointList : { abr : { hls = null }, renditions }} = descriptor

  if (hls !== null)
    return { src : hls, type: 'application/x-mpegURL', hls: { useLegacy: true } }
  else
    return { src: renditions[0].url, type: 'video/mp4' }
}

export const replaceVASTMacros = (vastUrl) =>
  vastUrl.replace(/\[[^\]]*]/gi, (macro) => {
    switch(macro){
      case '[timestamp]':
        return (new Date()).getTime();
      case '[referrer_url]':
      case '[description_url]':
        return encodeURIComponent(window.location.href);
      case '[playerHeight]':
        return '480';
      case '[playerWidth]':
        return '640';
      case '[playbackMethod]':
        return '-1';
      case '[maxAdBreakDuration]':
        return '30';
      case '[contentDuration]':
        return '60';
      default:
        return macro;
    }
  })

export const logger = (...args) => {
  console.log.apply(null, args)
}

export const speakerListManipulator = (speakerList) => {
  const chunk = speakerList.split(',')
  return (chunk.length === 1)
    ? chunk[0]
    : chunk.slice(0, chunk.length - 1).join(',') + ' e ' + chunk.slice(-1)
}

export const preloadImage = async (imageUrl = null) => {
  return new Promise((resolve, reject) => {
    try {
      if ((imageUrl === null) || (imageUrl === '')) {
        resolve(null)
      } else {
        const image = new Image()
        image.onerror = () => resolve(null)
        image.onload = () => resolve(imageUrl)
        image.src = imageUrl
      }
    } catch (e) {
      reject(e)
    }
  })
}

/// This function manages the show info
export const manageShow = async (metaInfo) => {
  let onAirInfo = null
  if (metaInfo !== null) {
    try {
      onAirInfo = {
        program: metaInfo.prg_title,
        speakers: speakerListManipulator(metaInfo.speakers),
        schedule: `dalle ${metaInfo.sch_time_from.substr(0, 2)}:${metaInfo.sch_time_from.substr(2, 2)} alle ${metaInfo.sch_time_to.substr(0, 2)}:${metaInfo.sch_time_to.substr(2, 2)}`,
        image: await preloadImage(metaInfo.image400 || null)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return onAirInfo
}

export const managePresent = async (metaInfo) => {
  let presentInfo = null

  if (metaInfo !== null) {
    try {
      if (Object.prototype.hasOwnProperty.call(metaInfo, 'class')) {
        switch (metaInfo['class']) {
          case 'Music':
            presentInfo = {
              type: 'song',
              title: metaInfo.mus_sng_title,
              artist: metaInfo.mus_art_name,
              album: (Object.prototype.hasOwnProperty.call(metaInfo, 'mus_sng_itunesalbumname') && metaInfo.mus_sng_itunesalbumname !== null) ? metaInfo.mus_sng_itunesalbumname.indexOf('(') !== -1 ? metaInfo.mus_sng_itunesalbumname.substr(0, metaInfo.mus_sng_itunesalbumname.indexOf('(')) : metaInfo.mus_sng_itunesalbumname : '',
              image: await preloadImage(metaInfo.mus_sng_itunescoverbig || null)
            }
            break
          case 'Commercial':
            presentInfo = {
              type: 'commercial',
              duration: metaInfo.duration,
              spotName: metaInfo.mus_sng_title,
              spotClaim: metaInfo.spotClaim
            }
            break
          default:
            break
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return presentInfo
}

/// This function manages the future property
export const manageFuture = async (metaInfo) => {
  let futureInfo = null

  if ((metaInfo !== null) && (Object.prototype.hasOwnProperty.call(metaInfo, 'class'))) {
    try {
      switch (metaInfo['class']) {
        case 'Music':
          futureInfo = {
            type: 'song',
            title: metaInfo.mus_sng_title,
            artist: metaInfo.mus_art_name
          }
          break
        default:
          break
      }
    } catch (e) { }
  }

  return futureInfo
}

export const preProcessMetadata = async (metadata = null) => {
  let parsedMetadata = null

  if (metadata !== null) {
    try {
      const jsonData = metadata.songInfo

      parsedMetadata = {
        show: await manageShow(jsonData.show || null),
        present: await managePresent(jsonData.present || null),
        future: await manageFuture(jsonData.future || null)
      }
    } catch (e) { }
  }

  return parsedMetadata
}

export const updateMediaSession = ({ title, artist, album, image }) => {
  if ('mediaSession' in navigator && 'MediaMetadata' in window) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title,
      artist,
      album,
      artwork: [
        { src: image, sizes: '600x600', type: 'image/jpeg' },
      ]
    });
  }
}

export const updateMediaSessionData = (metadata) => {
  if ('mediaSession' in navigator) {
    if (metadata.present !== null && metadata.present.type === 'song') {
      const { title, artist, album, image } = metadata.present
      updateMediaSession({ title, artist, album, image })
    } else if (metadata.show !== null) {
      const { program, speakers, image } = metadata.show
      updateMediaSession({
        title: program,
        artist: `Con ${speakers}`,
        album: '',
        image
      });
    }
  }
}

export const updateMetaData = async (metadata) => {
  const payload = await preProcessMetadata(JSON.parse(JSON.stringify(metadata)))

  let currentFingerPrint = ''

  if (payload === null) {
    currentFingerPrint = null
  } else if (payload.present !== null) {
    currentFingerPrint = JSON.stringify(payload.present)
  } else if (payload.show !== null) {
    currentFingerPrint = `${payload.show.program}`
  }

  // layout needs to be updated
  if (currentFingerPrint !== latestFingerPrint) {
    // update latest fingerprint
    latestFingerPrint = currentFingerPrint

    // Hide previous data
    setTimeout(() => {
      store.commit(TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX, false)
    }, metadata === null ? 0 : initialDelay)

    if (metadata !== null) {
      setTimeout(() => {
        store.commit(UPDATE_PLAYOUT_ONAIR_INFO, payload)
        updateMediaSessionData(payload)

        if (payload !== null) {
          // check if present is a song, if so add it to playlist
          if (payload.present !== null && payload.present.type === 'song') {
            NotificationManager.sendSongNotification(JSON.parse(JSON.stringify(payload.present)))
          }

          if ((payload.present !== null && payload.present.type === 'song') || (payload.show !== null)) {
            setTimeout(() => {
              // Show again
              store.commit(TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX, true)
            }, 250)
          }

          // if it's a spot track it and try to send a notification
          /*if (payload.present !== null && payload.present.type === 'commercial') {
            const { customParams = [] } = store.state.mediaInfo.vastClient || {}
            NotificationManager.sendCommercialNotification(JSON.parse(JSON.stringify(payload.present)), JSON.parse(JSON.stringify(customParams)))
          }*/
        }
        initialDelay = delay
      }, initialDelay + animationDuration + 250)
    }
  }
}

export const getAspectRatio = () => {
  const { clientWidth, clientHeight } = document.querySelector('.skin-container')
  //return aspectRatio <= 1.33 ? PAGE_ORIENTATION.LANDSCAPE : PAGE_ORIENTATION.PORTRAIT
  return clientWidth > clientHeight ? PAGE_ORIENTATION.LANDSCAPE : PAGE_ORIENTATION.PORTRAIT
}

export const loadSkinFromADServer = (source = MEDIA_SOURCE.LIVE) => {
  //loadSkinFromDFP(source)
  loadSkinFromEquativ(source);
}

export const convertKeywordTargetingToString = (targeting = {}) => {
  let target = "";

  for (const [key, value] of Object.entries(targeting)) {
    if (value != ""){
      let values = String(value).split(",");
      target += values.map(n => `${key}=${n}`).join(";") + ";"
    }
  }

  return target;
}

export const loadSkinFromEquativ = async (source = MEDIA_SOURCE.LIVE) => {
  clearTimeout(loadSkinFromAdServerTimeout)

  const { vastClient : { customParams = {} }, broadcasterLabel = 1 } = store.state.mediaInfo.currentMedia || {}

  store.commit(UPDATE_INFO_SKIN, {
    content: false,
    link: false,
    image: false
  })

  customParams['broadcasterLabel'] = broadcasterLabel;
  customParams['orientation'] = getAspectRatio().toLowerCase();

  const { SITE_ID, PAGE_ID, FORMAT_ID } = (source == MEDIA_SOURCE.LIVE) ? EQUATIV_ZONES.SKIN_LIVE : EQUATIV_ZONES.SKIN_ON_DEMAND;

  const apiUrl = new URL("https://adapi.smartadserver.com/ac");

  apiUrl.searchParams.append('sideid', SITE_ID)
  apiUrl.searchParams.append('pgid', PAGE_ID)
  apiUrl.searchParams.append('fmtid', FORMAT_ID)
  apiUrl.searchParams.append('visit', 'M')
  apiUrl.searchParams.append('tmstp', (new Date()).getTime())
  //apiUrl.searchParams.append('tgt', (new URLSearchParams(customParams).toString()).replace("&", ","))
  apiUrl.searchParams.append('tgt', convertKeywordTargetingToString(customParams))
  apiUrl.searchParams.append('out', 'json')

  try {
    const adJSON = await fetch(apiUrl.href)
      .then(async res => {
        const resText = await res.text();
        if (resText !== "")
          return JSON.parse(resText)
        else
          return Promise.resolve(null);
      });

    if (adJSON !== null){
      const { ad: { clickUrl, impUrls, creativeUrl, width, height }} = adJSON;

      store.commit(UPDATE_INFO_SKIN, {
        content: `<img src="${impUrls}" width="${width}" height="${height}" />`,
        link: clickUrl,
        image: creativeUrl,
        size: { width, height }
      })
      store.commit(UPDATE_SHOW_SKIN, true)
    }
  }
  catch(e){
    console.error(e);
  }

  if (ADV_RELOAD_TIME.SKIN > 0) {
    loadSkinFromAdServerTimeout = setTimeout(() => loadSkinFromEquativ(source), ADV_RELOAD_TIME.SKIN)
  }
}
/*
export const loadSkinFromDFP = (source = MEDIA_SOURCE.LIVE) => {
  clearTimeout(loadSkinFromDFPTimeout)

  const { customParams: customTargeting = [] } = store.state.mediaInfo.vastClient || {}

  store.commit(UPDATE_INFO_SKIN, {
    content: false,
    link: false,
    image: false
  })

  const slotName =
    getAspectRatio() === PAGE_ORIENTATION.LANDSCAPE
      ? (source == MEDIA_SOURCE.LIVE) ? DFP_DOM_ELEMENTS_ID.SKIN_LANDSCAPE_LIVE : DFP_DOM_ELEMENTS_ID.SKIN_LANDSCAPE_ON_DEMAND
      : (source == MEDIA_SOURCE.LIVE) ? DFP_DOM_ELEMENTS_ID.SKIN_PORTRAIT_LIVE : DFP_DOM_ELEMENTS_ID.SKIN_PORTRAIT_ON_DEMAND

  const slot = googleTagManager.getSlot(slotName)
  if (slot !== false) {
    slot
      .clearTargeting();
    for(let key in customTargeting){
      slot
        .setTargeting(key, customTargeting[key]);
    }
  }

  googleTagManager
    .renderSlot(slotName)
    .then((googleTagItem) => googleTagItem.getContents())
    .then(([contentImage, contentUrl, googleTagItem]) => {
      if ((contentImage !== null) && (contentUrl !== null)) {
        if ((contentImage.width > 1) && (contentImage.height > 1)) {
          store.commit(UPDATE_INFO_SKIN, {
            content: '<div>dfpSkin</div>',
            link: contentUrl,
            image: contentImage.src
          })
          store.commit(UPDATE_SHOW_SKIN, true)
        }
      }
    })
    .catch(aReason => console.error(aReason))

  if (ADV_RELOAD_TIME.SKIN > 0) {
    loadSkinFromDFPTimeout = setTimeout(() => loadSkinFromDFP(source), ADV_RELOAD_TIME.SKIN)
  }
}
*/
export const managePrerollCompanions = (advCompanions) => {
  // Check for skins

  const { WIDTH, HEIGHT } = getAspectRatio() === PAGE_ORIENTATION.LANDSCAPE ? BANNER_SIZE.SKIN.LANDSCAPE : BANNER_SIZE.SKIN.PORTRAIT

  const RATIO = WIDTH / HEIGHT;

  const skins = advCompanions.filter(({ width, height }) => (width / height) === RATIO /*width === WIDTH && height === HEIGHT*/)

  let tempContainer = null

  if (skins.length > 0) {
    let { contentHTML = null, clickThrough = null, resourceURI = null } = skins[0];

    if (contentHTML !== null || (clickThrough !== null && resourceURI !== null)) {
      if (clickThrough === null || resourceURI === null){

        tempContainer = document.createElement('div')
        tempContainer.innerHTML = contentHTML

        clickThrough = tempContainer.querySelector('a').attributes.href.value
        resourceURI = tempContainer.querySelector('img').attributes.src.value
      }

      store.commit(UPDATE_INFO_SKIN, {
        content: contentHTML,
        link: clickThrough,
        image: resourceURI
      })

      store.commit(UPDATE_SHOW_SKIN, false)
    }

  }

  tempContainer = undefined
}

export const clearNielsenTracking = () => {
  if (nielsenInstance !== null){
    nielsenInstance.destroy();
    nielsenInstance = null;
  }
}

export const manageNielsenTracking = (player, currentMedia, nielsenVideoAppIds = []) => {
  // Destroy previous tracking
  clearNielsenTracking()
  const { nielsenDCRMetadata, nielsenTracking } = currentMedia

  const appIds = nielsenVideoAppIds
                    .filter(n =>
                        (currentMedia.type === MEDIA_TYPE.VIDEO && n.canTrackVideo) ||
                        (currentMedia.type === MEDIA_TYPE.AUDIO && n.canTrackAudio)
                    )
                    .map(({appId}) => appId)

  if (nielsenTracking === true && appIds.length > 0) {

    const { assetid, program, title, airDate, adloadtype, isAudio = null} = nielsenDCRMetadata;

    if (currentMedia.type === MEDIA_TYPE.VIDEO) {

      const DCRMetadata = new window.THEOplayerNielsenSDK.DCRMetadata(isAudio, assetid, program, title, airDate, adloadtype);

      if (!nielsenInstance) {
        let isProductionTracking = false;
        nielsenInstance = new window.THEOplayerNielsenSDK.NielsenTheoDCR(appIds, player, isProductionTracking, DCRMetadata);
      }

      nielsenInstance.setMetadata(DCRMetadata);
    }
  }
}

export const enterFullscreenMode = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

export const exitFullscreenMode = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE/Edge */
    document.msExitFullscreen();
  }
};

export const doAuthentication = async () => {
  store.commit(FIREBASE_AUTH_START)
  Firebase.doSignInAnonymously()
    .then((res) => {
      const { uid, isAnonymous, displayName, email, emailVerified, phoneNumber, photoURL } = res.user;
      const { isNewUser, providerId } = res.additionalUserInfo;
      store.commit(FIREBASE_AUTH_SUCCESS, { uid, isAnonymous, displayName, email, emailVerified, phoneNumber, photoURL, isNewUser, providerId });
    })
    .catch((error) => {
      store.commit(FIREBASE_AUTH_FAILED);
    })
};

// this method emits the media Played event
export const emitMediaPlayedEvent = (mediaInfo, trackingInfos) => {
  if (mediaInfo !== null){
    const playEvent = new CustomEvent('media-played', { detail : { mediaInfo : JSON.parse(JSON.stringify(mediaInfo)) } });

    document.dispatchEvent(playEvent);
  }
}
