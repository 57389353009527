<style lang="scss" scoped>
    @import './OnAirInfoBox.scss';
</style>
<template>
  <div v-bind:class="containerClass">
    <OnAirInfoBoxShow :show="show" :media="currentMedia" />
    <OnAirInfoBoxSong :present="present" />
  </div>
</template>
<script>
import { MEDIA_SOURCE, MEDIA_TYPE } from '../../lib/constants'
import OnAirInfoBoxShow from '../OnAirInfoBoxShow/OnAirInfoBoxShow'
import OnAirInfoBoxSong from '../OnAirInfoBoxSong/OnAirInfoBoxSong'
export default {
  name: 'OnAirInfoBox',
  components: {
    OnAirInfoBoxShow,
    OnAirInfoBoxSong
  },
  computed: {
    containerClass () {
      return { 'onair-info-box': true, show: this.mediaType === MEDIA_TYPE.AUDIO && !this.isPrerollPlaying && (this.show !== null || this.present !== null || (this.mediaSource === MEDIA_SOURCE.ON_DEMAND && this.currentMedia !== null)) }
    },
    currentMedia () {
      return this.$store.state.mediaInfo.currentMedia
    },
    mediaType () { return this.currentMedia.type },
    mediaSource () { return this.currentMedia.source },
    isPrerollPlaying () { return this.$store.state.layout.isPrerollPlaying },
    showOnAirInfoBox () { return this.$store.state.layout.showOnAirInfoBox },
    onAirInfo () { return this.$store.state.playoutInfo.onAirInfo },
    show () {
      if (typeof this.onAirInfo !== 'undefined') {
        if (this.onAirInfo !== null) {
          if (Object.prototype.hasOwnProperty.call(this.onAirInfo, 'show') && this.onAirInfo.show !== null) {
            return this.onAirInfo.show
          }
        }
      }
      return null
    },
    present () {
      if (typeof this.onAirInfo !== 'undefined') {
        if (this.onAirInfo !== null) {
          if (Object.prototype.hasOwnProperty.call(this.onAirInfo, 'present') && this.onAirInfo.present !== null) {
            if (Object.prototype.hasOwnProperty.call(this.onAirInfo.present, 'type')) {
              if (this.onAirInfo.present.type === 'song') {
                return this.onAirInfo.present
              }
            }
          }
        }
      }

      return null
    }
  }
}
</script>
