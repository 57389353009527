/* eslint-disable */
//import Push from 'push.js';
import googleTagManager from '../lib/googleTagManager';
import store from '../store/index'
import { UPDATE_LAYOUT_CAN_SEND_NOTIFICATION } from '../store/mutation-types'
import { DFP_DOM_ELEMENTS_ID } from '../lib/constants';

const Push = window.Push || {};

class NotificationManager {

  initializePermissions() {

    const hasPermission = Push.Permission.has();

    store.commit(UPDATE_LAYOUT_CAN_SEND_NOTIFICATION, hasPermission);

    if (!hasPermission) {
      Push.Permission.request(() => store.commit(UPDATE_LAYOUT_CAN_SEND_NOTIFICATION, true), () => store.commit(UPDATE_LAYOUT_CAN_SEND_NOTIFICATION, false));
    }

  }

  sendSongNotification(songInfo) {
    if (Push.Permission.has()) {
      try {
        const { artist, title, image } = songInfo;

        // Send Desktop notification !
        Push.create('Stai ascoltando', {
          body: `${artist} - ${title}`,
          icon: image,
          tag: 'ONAIR',
          timeout: 8000
        });
      }
      catch (e) { }
    }
  }
  /*
  sendCommercialNotification(commercialMetaInfo, customTargeting) {

    const { duration, spotName, spotClaim } = commercialMetaInfo;
    //const { thronTrackingCode } = channel;

    // Track spot audio
    const spotAudioSlot = googleTagManager.getSlot(DFP_DOM_ELEMENTS_ID.SPOT_AUDIO);

    if (spotAudioSlot !== false) {
      spotAudioSlot
        .clearTargeting()
        .setTargeting('spot-name', spotName);
      
      for(let key in customTargeting){
        spotAudioSlot
          .setTargeting(key, customTargeting[key]);
      }
      
      googleTagManager.renderSlot(DFP_DOM_ELEMENTS_ID.SPOT_AUDIO);
    }

    // Show notification
    if (spotClaim !== null && spotClaim !== "") {

      const spotNotificationSlot = googleTagManager.getSlot(DFP_DOM_ELEMENTS_ID.SPOT_AUDIO_NOTIFICATION);

      if (spotNotificationSlot !== false) {
        spotNotificationSlot
          .clearTargeting()
          .setTargeting('spot-name', spotName)
        for(let key in customTargeting){
          spotNotificationSlot
            .setTargeting(key, customTargeting[key]);
        }
        
        googleTagManager
          .renderSlot(DFP_DOM_ELEMENTS_ID.SPOT_AUDIO_NOTIFICATION)
          .then((googleTagItem) => googleTagItem.getContents())
          .then(([contentImage, contentUrl, googleTagItem]) => {
            if ((contentImage !== null) && (contentUrl !== null)) {
              if ((contentImage.width > 1) && (contentImage.height > 1)) {
                const notificationTitle = spotClaim.indexOf('|' !== -1) ? spotClaim.split('|')[0] : 'Pubblicità';
                const notificationBody = spotClaim.indexOf('|' !== -1) ? spotClaim.split('|')[1] : spotClaim;

                this.sendAdvertisingNotification(
                  {
                    title: notificationTitle,
                    body: notificationBody,
                    image: contentImage.src,
                    link: contentUrl,
                    tag: 'COMMERCIAL-NOTIFICATION',
                    timeout: (duration > 0) ? duration - 500 : 5000
                  }
                );
              }
            }
          });
      }
    }
  }

  sendAdvertisingNotification(advertisingInfo) {
    if (Push.Permission.has()) {

      try {
        const { title, body, image, link, tag, timeout } = advertisingInfo;

        // Send Desktop notification !
        Push.create(title || 'Pubblicità', {
          body,
          icon: image,
          tag: tag || 'advertising',
          timeout: timeout || 8000,
          onClick: function () {
            if (link) {
              window.open(link, tag);
            }
            this.close();
          }
        });
      }
      catch (e) { }
    }
  }*/
}

export default new NotificationManager();
