<style lang="scss" scoped>
    @import './OnAirInfoBoxSong.scss';
</style>
<template>
  <div v-bind:class="containerClass">
    <div class="image-container">
      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" :style="{ backgroundImage: 'url(' + image + ')' }" :alt="firstLine"/>
    </div>
    <div class="info-container">
      <div class="first-line">{{firstLine}}</div>
      <div class="second-line">{{secondLine}}</div>
      <div class="third-line">{{thirdLine}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OnAirInfoBoxSong',

  props: {
    present: {
      type: Object,
      default: null
    }
  },

  computed: {
    containerClass () {
      return { 'onair-info-box-song': true, show: this.present !== null }
    },
    firstLine () {
      if ((this.present !== null) && Object.prototype.hasOwnProperty.call(this.present, 'title') && this.present.title !== '') {
        return this.present.title
      } else {
        return ''
      }
    },
    secondLine () {
      if ((this.present !== null) && Object.prototype.hasOwnProperty.call(this.present, 'artist') && this.present.artist !== '') {
        return this.present.artist
      } else {
        return ''
      }
    },
    thirdLine () {
      if ((this.present !== null) && Object.prototype.hasOwnProperty.call(this.present, 'album') && this.present.album !== '') {
        return this.present.album
      } else {
        return ''
      }
    },
    image () {
      if ((this.present !== null) && Object.prototype.hasOwnProperty.call(this.present, 'image') && this.present.image !== '') {
        return this.present.image
      } else {
        return 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
      }
    }
  }
}
</script>
