import { UPDATE_CURRENT_MEDIA, UPDATE_RELATED_MEDIA, SWITCH_MEDIA, TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX, UPDATE_PLAYOUT_ONAIR_INFO, UPDATE_NIELSEN_VIDEO_APP_ID, RESET_TO_DEFAULT } from '../mutation-types'
import ClientTracker from '../../lib/clientTracker'
import { /* trackGoogleAnalitycsEvent */ emitMediaPlayedEvent } from '../../lib/utility'

export const initialState = {
  currentMedia: null,
  relatedMedia: null,
  nielsenVideoAppIds: []
}

const mediaInfoModule = {
  state: () => ({ ...initialState }),
  mutations: {
    [UPDATE_CURRENT_MEDIA] (state, mediaInfo) {
      state.currentMedia = mediaInfo
      // Track listening in firebase
      ClientTracker.trackMediaInfoChange(mediaInfo)
      // Track GA event
      emitMediaPlayedEvent(mediaInfo)
      // clear previous info
      this.commit(UPDATE_PLAYOUT_ONAIR_INFO, null)
      this.commit(TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX, false)
    },
    [UPDATE_RELATED_MEDIA] (state, mediaInfo) {
      state.relatedMedia = mediaInfo
    },
    /* [UPDATE_ADV_TAG] (state, advTag) {
      state.advTag = advTag
    }, */
    [SWITCH_MEDIA] (state) {
      const { currentMedia, relatedMedia } = state
      // Track listening in firebase
      ClientTracker.trackMediaInfoChange(relatedMedia)
      // Track GA event
      emitMediaPlayedEvent(relatedMedia)
      state.currentMedia = relatedMedia
      state.relatedMedia = currentMedia
    },
    [UPDATE_NIELSEN_VIDEO_APP_ID] (state, nielsenVideoAppIds) {
      state.nielsenVideoAppIds = nielsenVideoAppIds
    }
  },
  actions: {
    [RESET_TO_DEFAULT] ({ commit }) {
      commit(UPDATE_CURRENT_MEDIA, null)
      commit(UPDATE_RELATED_MEDIA, null)
      commit(UPDATE_NIELSEN_VIDEO_APP_ID, null)
    }
  }
}

export default mediaInfoModule
