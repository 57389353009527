/* eslint quote-props: ["error", "consistent"] */
// Media Type
export const MEDIA_TYPE = {
  AUDIO: 201,
  VIDEO: 202
}

// Media Channel
export const MEDIA_CHANNEL = {
  THRON: 1,
  YOUTUBE: 2,
  MAINSTREAMING: 7,
  WEB: 50
}

// Media Source
export const MEDIA_SOURCE = {
  LIVE: 1,
  ON_DEMAND: 2
}

// EQUATIV ZONES
export const EQUATIV_ZONES = {
  SKIN_LIVE : {
    SITE_ID : 594697,
    PAGE_ID : 1820712,
    FORMAT_ID : 122716
  },
  SKIN_ON_DEMAND : {
    SITE_ID : 594697,
    PAGE_ID : 1820713,
    FORMAT_ID : 122716
  }
}

// DFP DOM IDs
export const DFP_DOM_ELEMENTS_ID = {
  SKIN_LANDSCAPE_LIVE: 'dfp-skin-landscape-live',
  SKIN_LANDSCAPE_ON_DEMAND: 'dfp-skin-landscape-on-demand',
  SKIN_PORTRAIT_LIVE: 'dfp-skin-portrait-live',
  SKIN_PORTRAIT_ON_DEMAND: 'dfp-skin-portrait-on-demand',
  SPOT_AUDIO: 'dpf-spot-audio',
  SPOT_AUDIO_NOTIFICATION: 'dfp-spot-notification'
}

// Page orientation
export const PAGE_ORIENTATION = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT'
}

// Banner sizes
export const BANNER_SIZE = {
  SKIN: {
    LANDSCAPE: {
      WIDTH: 1920,
      HEIGHT: 1080
    },
    PORTRAIT: {
      WIDTH: 1080,
      HEIGHT: 1920
    }
  },
  AUDIO: {
    WIDTH: 1,
    HEIGHT: 1
  }
}

export const DFP_PREROLL = {
  LIVE: `https://pubads.g.doubleclick.net/gampad/ads?sz=640x360&iu=/7110011/${window.GPT_PREFIX}-preroll-live&ciu_szs=200x200,1920x1080,1080x1920&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=[description_url]&correlator=[timestamp]`,
  ON_DEMAND: `https://pubads.g.doubleclick.net/gampad/ads?sz=640x360&iu=/7110011/${window.GPT_PREFIX}-preroll-ondemand&ciu_szs=200x200,1920x1080,1080x1920&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=[description_url]&correlator=[timestamp]`
}

// DFP available zones
export const DFP_ZONES = [
  { unitName: `/7110011/${window.GPT_PREFIX}-skin-live`, unitSize: [BANNER_SIZE.SKIN.LANDSCAPE.WIDTH, BANNER_SIZE.SKIN.LANDSCAPE.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SKIN_LANDSCAPE_LIVE },
  { unitName: `/7110011/${window.GPT_PREFIX}-skin-on-demand`, unitSize: [BANNER_SIZE.SKIN.LANDSCAPE.WIDTH, BANNER_SIZE.SKIN.LANDSCAPE.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SKIN_LANDSCAPE_ON_DEMAND },
  { unitName: `/7110011/${window.GPT_PREFIX}-skin-live`, unitSize: [BANNER_SIZE.SKIN.PORTRAIT.WIDTH, BANNER_SIZE.SKIN.PORTRAIT.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SKIN_PORTRAIT_LIVE },
  { unitName: `/7110011/${window.GPT_PREFIX}-skin-on-demand`, unitSize: [BANNER_SIZE.SKIN.PORTRAIT.WIDTH, BANNER_SIZE.SKIN.PORTRAIT.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SKIN_PORTRAIT_ON_DEMAND },
  // { unitName: `/7110011/${window.GPT_PREFIX}-skin-portrait`, unitSize: [BANNER_SIZE.SKIN.PORTRAIT.WIDTH, BANNER_SIZE.SKIN.PORTRAIT.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SKIN_PORTRAIT_LIVE },
  // { unitName: `/7110011/${window.GPT_PREFIX}-skin-portrait-on-demand`, unitSize: [BANNER_SIZE.SKIN.PORTRAIT.WIDTH, BANNER_SIZE.SKIN.PORTRAIT.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SKIN_PORTRAIT_ON_DEMAND },
  { unitName: `/7110011/${window.GPT_PREFIX}-spot-audio`, unitSize: [BANNER_SIZE.AUDIO.WIDTH, BANNER_SIZE.AUDIO.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SPOT_AUDIO },
  // { unitName: `/7110011/${window.GPT_PREFIX}-spot-notify`, unitSize: [BANNER_SIZE.NOTIFICATION.WIDTH, BANNER_SIZE.NOTIFICATION.HEIGHT], domId: DFP_DOM_ELEMENTS_ID.SPOT_AUDIO_NOTIFICATION }
]

export const ADV_RELOAD_TIME = {
  SKIN: 15 * 60 * 1000,
  PREROLL: 10 * 60 * 1000
}

export const ADV_PREROLL = {
  LIVE      : `https://videoapi.smartadserver.com/ac?siteid=594697&pgid=1820712&fmtid=118228&ab=1&tgt=&oc=1&out=vast4&ps=1&pb=0&visit=S&vcn=s&vph=[playerHeight]&vpw=[playerWidth]&vpmt=[playbackMethod]&skip=&mabd=[maxAdBreakDuration]&ctd=[contentDuration]&tmstp=[timestamp]&pgDomain=play.rtl.it`,
  ON_DEMAND : `https://videoapi.smartadserver.com/ac?siteid=594697&pgid=1820713&fmtid=118228&ab=1&tgt=&oc=1&out=vast4&ps=1&pb=0&visit=S&vcn=s&vph=[playerHeight]&vpw=[playerWidth]&vpmt=[playbackMethod]&skip=&mabd=[maxAdBreakDuration]&ctd=[contentDuration]&tmstp=[timestamp]&pgDomain=play.rtl.it`,
}

export const THEO_DEFAULT_CONFIG = {
  libraryLocation: 'https://cloud.rtl.it/SDK/THEO/7.2.0',
  license: 'sZP7IYe6T6fZIu5oIK0t3Ozz3S1lFSakCLb-Clgg3mztCoP63Dx6CLBzIQC6FOPlUY3zWokgbgjNIOf9flXe0SUeIuezFDBr3LC-3Q4l0ZzrTD0rFDPgIlCZTDhz3ug10OfVfK4_bQgZCYxNWoryIQXzImf90Sbz3LRZ0SCi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3LBz3L0Z3lRZ3SbtFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya',
  cast: {
    chromecast: {
      appID: '1C3350A2'
    }
  },
  preload: 'auto',
  ui: {
    language: 'it',
    languages: {
      'it': {
        'Play': 'Play',
        'Pause': 'Pausa',
        'Current Time': 'Orario attuale',
        'Duration Time': 'Durata',
        'Remaining Time': 'Tempo rimanente',
        'Stream Type': 'Tipo del Streaming',
        'LIVE': 'LIVE',
        'Loaded': 'Caricato',
        'Progress': 'Stato',
        'Fullscreen': 'Schermo intero',
        'Non-Fullscreen': 'Chiudi schermo intero',
        'Mute': 'Muto',
        'Unmute': 'Audio',
        'Playback Rate': 'Tasso di riproduzione',
        'Related content': 'Contenuti correlati',
        'Settings': 'Impostazioni',
        'Subtitles': 'Sottotitoli',
        'subtitles off': 'Senza sottotitoli',
        'captions off': 'Senza sottotitoli non udenti',
        'Chapters': 'Capitolo',
        'Quality': 'Qualità',
        'Speed': 'Velocità',
        'You aborted the media playback': 'La riproduzione del filmato è stata interrotta.',
        'A network error caused the media download to fail part-way.': 'Il download del filmato è stato interrotto a causa di un problema rete.',
        'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Il filmato non può essere caricato a causa di un errore nel server o nella rete o perchè il formato non viene supportato.',
        'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'La riproduzione del filmato è stata interrotta a causa di un file danneggiato o per lâ€™utilizzo di impostazioni non supportate dal browser.',
        'No compatible source was found for this media.': 'Non ci sono fonti compatibili per questo filmato.',
        'Video playing in Picture-in-Picture mode': 'Video in modalità Picture-in-Picture'
      }
    }
  }
}
