<style lang="scss" scoped>
  @import './PlayerManager.scss';
</style>
<template>
  <div v-bind:class="containerClass">
    <div class="player-wrapper">
      <YoutubePlayer v-bind:current-media="currentMedia" v-if="currentMedia.channel === MEDIA_CHANNEL.YOUTUBE" />
      <THEOPlayer v-bind:current-media="currentMedia" v-bind:adv-tag="advTag" v-bind:nielsen-video-app-ids="nielsenVideoAppIds" v-if="isTHEOPlayer" />
    </div>
  </div>
</template>
<script>

import THEOPlayer from '../THEOPlayer/THEOPlayer'
import YoutubePlayer from '../YoutubePlayer/YoutubePlayer'
import { MEDIA_CHANNEL, MEDIA_SOURCE, MEDIA_TYPE } from '../../lib/constants'

export default {
  name: 'PlayerManager',

  created () {
    this.MEDIA_CHANNEL = MEDIA_CHANNEL
    this.MEDIA_TYPE = MEDIA_TYPE
  },

  components: {
    YoutubePlayer,
    THEOPlayer
  },
  computed: {
    containerClass () {
      return {
        'player-manager': true,
        'preroll-is-playing': this.$store.state.layout.isPrerollPlaying,
        video: this.currentMedia.type === MEDIA_TYPE.VIDEO,
        audio: this.currentMedia.type === MEDIA_TYPE.AUDIO,
        'on-demand': this.currentMedia.source === MEDIA_SOURCE.ON_DEMAND,
        live: this.currentMedia.source === MEDIA_SOURCE.LIVE
      }
    },
    currentMedia () {
      return this.$store.state.mediaInfo.currentMedia
    },
    advTag () {
      return this.$store.state.mediaInfo.advTag
    },
    nielsenVideoAppIds () {
      return this.$store.state.mediaInfo.nielsenVideoAppIds
    },
    isTHEOPlayer () {
      return this.currentMedia.channel === MEDIA_CHANNEL.THRON || this.currentMedia.channel === MEDIA_CHANNEL.WEB || this.currentMedia.channel === MEDIA_CHANNEL.MAINSTREAMING
    }
  }
}
</script>
