<style lang="scss">
  @import './THEOPlayer.scss';
</style>
<template>
  <div id="player" ref="player" class="theoplayer-container video-js theoplayer-skin vjs-16-9" :class="additionalClasses"></div>
</template>
<script>
import { ADV_PREROLL, ADV_RELOAD_TIME, MEDIA_SOURCE, MEDIA_TYPE, THEO_DEFAULT_CONFIG } from '../../lib/constants'
import { UPDATE_CURRENT_MEDIA, TOGGLE_SHOW_PREROLL, TOGGLE_PREROLL_IS_PLAYING, TOGGLE_SHOW, CHANGE_PLAYOUT_IS_PLAYING } from '../../store/mutation-types'
import { updateMetaData, loadSkinFromADServer, enterFullscreenMode, exitFullscreenMode, managePrerollCompanions, replaceVASTMacros, parseMediaSource, manageNielsenTracking, clearNielsenTracking } from '../../lib/utility'

export default {
  name: 'THEOPlayer',

  props: {
    currentMedia: {
      type: Object,
      default: null
    },
    nielsenVideoAppIds: {
      type: Array,
      default: () => []
    }/* ,
    advTag: {
      type: Object,
      default: null
    } */
  },

  data: () => ({
    firstCycle: true
  }),

  computed: {
    additionalClasses() { 
      return (this.currentMedia.type === MEDIA_TYPE.AUDIO && this.currentMedia.source === MEDIA_SOURCE.ON_DEMAND)
        ? 'audio-js'
        : '';
    },
    fullscreen () { return this.$store.state.layout.toggleFullscreen },
    isFullscreen () { return this.$store.state.playoutInfo.isFullscreen },
    changeState () { return this.$store.state.layout.togglePlayPause },
    volume () { return this.$store.state.playoutInfo.volume },
    showPreroll () { return this.$store.state.advertising.showPreroll },
    showAdvertising () { return this.$store.state.advertising.showAdvertising },
    showSkin () { return this.$store.state.advertising.showSkin }
  },

  created: function () {
    this.player = null
    this.currentMetaData = null
  },

  mounted: function () {
    this.initializePlayer()
  },

  watch: {
    fullscreen (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.toggleFullscreen(newValue)
      }
    },
    changeState (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.togglePlay(newValue)
      }
    },
    volume (newValue) {
      const volume = newValue / 100
      this.player.volume = volume
    },
    currentMedia () {
      this.destroyPlayer()
      this.initializePlayer()
    }
  },

  methods: {
    initializePlayer () {
      this.currentMetaData = null
      updateMetaData(this.currentMetaData)
      const { uri, poster, source, channel, descriptor = {} } = this.currentMedia
      parseMediaSource({ uri, channel, source, descriptor })
        .then(mediaSource => {
          // check for thumbVTT
          const { thumbnails: { thumbVTT = null } = {} } = descriptor

          // check if adv tag
          let { vastClient: { adTagUrl = null, enable: prerollEnable = false } } = this.currentMedia

          // adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?sz=640x360&iu=/7110011/dev-preroll-on-demand&ciu_szs=200x200,1080x1920,1920x1080,180x150,300x250,728x90&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=[description_url]&correlator=[timestamp]`;

          // parse Macros in VAST url
          const prerollTag = replaceVASTMacros(
            (adTagUrl === null)
              ? (source === MEDIA_SOURCE.LIVE ? ADV_PREROLL.LIVE : ADV_PREROLL.ON_DEMAND)
              : adTagUrl
          )

          // initialize player
          this.player = new window.THEOplayer.Player(this.$refs.player, Object.assign({ controls: source !== MEDIA_SOURCE.LIVE }, THEO_DEFAULT_CONFIG))

          // Add Nielsen Tracking
          if (this.nielsenVideoAppIds !== null && this.nielsenVideoAppIds.length > 0) {
            manageNielsenTracking(this.player, this.currentMedia, this.nielsenVideoAppIds)
          }

          // add source
          this.player.source = {
            sources: [mediaSource],
            poster,
            textTracks: [
              thumbVTT !== null ? {
                default: true,
                src: thumbVTT,
                label: 'thumbnails',
                kind: 'metadata'
              } : null
            ],
            // advertising config
            ads: [
              (this.showPreroll && this.showAdvertising && prerollEnable)
                ? { sources: prerollTag, timeOffset: 'start' , integration: 'google-ima' }
                : null
            ]
          }
          // event binding
          this.player.addEventListener('play', () => this.onPlay())
          this.player.addEventListener('pause', () => this.onPause())
          this.player.addEventListener('ended', () => this.onComplete())
          this.player.addEventListener('destroy', () => clearNielsenTracking())

          // override ABR Quality strategy selector
          // this.player.videoTracks.addEventListener('addtrack', () => this.onVideoAddTrack())

          // metadata event binding
          this.player.textTracks.addEventListener('addtrack', ({ track }) => {
            track.addEventListener('cuechange', (cueChangeEvent) => {
              try {
                const { track } = cueChangeEvent
                const metadatas = new Map()

                track.activeCues.forEach(({ content: { id, text } }) => metadatas.set(id, text))

                this.onMetaData(metadatas.get('TEXT') || metadatas.get('TIT1'))
              } catch (e) {
                // Log
              }
            })
          })
          // ads event binding
          this.player.ads.addEventListener(['adbegin'], ({ ad }) => {
            this.onAdvStarted()
            managePrerollCompanions(ad.companions)
          })
          this.player.ads.addEventListener(['adbreakchange'], ({ ad: { ads } }) => {
            // if no ads launch skin from DFP
            if (ads.length === 0) {
              this.onPrerollCompleted()
            }
          })
          this.player.ads.addEventListener(['adend', 'aderror'], () => this.onPrerollCompleted())

          // customize context menu
          const contextMenu = this.$refs.player.querySelector('.theo-context-menu')
          contextMenu.innerHTML = '<li><div class="theo-context-version">&copy; RTL 102.5 Play</div></li>'

          // let's play
          setTimeout(() => this.player.play(), 0)

          // window.player = this.player
        })
    },
    destroyPlayer () {
      if (this.player !== null) {
        this.player.destroy()
        this.player = null
      }
    },
    togglePlay () {
      const { paused } = this.player
      if (paused) {
        this.player.play()
      } else {
        this.player.pause()
      }
    },
    toggleFullscreen () {
      const { type } = this.currentMedia
      if (type === MEDIA_TYPE.AUDIO) {
        if (!this.isFullscreen) {
          enterFullscreenMode(document.body)
        } else {
          exitFullscreenMode(document.body)
        }
      } else {
        const isFullscreen = this.player.ui.isFullscreen()
        if (isFullscreen) {
          this.player.ui.exitFullscreen()
        } else {
          this.player.ui.requestFullscreen()
        }
      }
    },
    togglePrerollPlaying (isPlaying) {
      if (isPlaying) {
        this.$store.commit(TOGGLE_SHOW_PREROLL, false)
      }
      this.$store.commit(TOGGLE_PREROLL_IS_PLAYING, isPlaying)
    },
    isMetaDataChanged (newMetaData) {
      const currentMetaString = JSON.stringify(this.currentMetaData)
      const newMetaString = JSON.stringify(newMetaData)
      return currentMetaString !== newMetaString
    },
    resetABRResetLogin () {
      if (this.player.buffered.length > 0) {
        // switch to normal ABR when THEO buffered beyond 10 seconds
        if (this.player.buffered.end(this.player.buffered.length - 1) > 10) {
          this.player.videoTracks[0].targetQuality = null
          this.player.removeEventListener('progress', this.resetABRResetLogin)
        }
      }
    },
    // EVENTS HANDLERS
    onMetaData (metadata) {
      try {
        const { type } = this.currentMedia
        // Add listen only on Audio
        if (type === MEDIA_TYPE.AUDIO) {
          const json = JSON.parse(
            metadata.substring((metadata.charAt(0) === '-') ? 1 : 0, metadata.length)
          )

          if (this.isMetaDataChanged(json)) {
            this.currentMetaData = json
            updateMetaData(json)
          }
        }
      } catch (e) {
        console.error('error parsing json', e.message)
      }
    },
    onVideoAddTrack () {
      try {
        const qualitySortedDesc = this.player.videoTracks[0].qualities.sort((a, b) => b.width - a.width)

        if (qualitySortedDesc.length > 0) {
          this.player.videoTracks[0].targetQuality = qualitySortedDesc[0]
        }

        // reset override after progress
        this.player.addEventListener('progress', () => this.resetABRResetLogin())
      } catch (e) {
        console.error('error parsing json', e.message)
      }
    },
    onPlay () {
      const { source, type } = this.currentMedia
      if (!this.showPreroll || !this.showAdvertising) {
        this.onPrerollCompleted()
        if (source === MEDIA_SOURCE.ON_DEMAND && type === MEDIA_TYPE.AUDIO) {
          this.player.element.querySelector('.theoplayer-poster').style.visibility = 'unset'
        }
      }
      this.togglePrerollPlaying(false)
      this.$store.commit(CHANGE_PLAYOUT_IS_PLAYING, true)
      this.player.volume = this.volume / 100
    },
    onPause () {
      this.$store.commit(CHANGE_PLAYOUT_IS_PLAYING, false)
    },
    onComplete () {
      this.$store.commit(CHANGE_PLAYOUT_IS_PLAYING, false)
      this.$store.commit(TOGGLE_SHOW, false)
      this.$store.commit(UPDATE_CURRENT_MEDIA, null)
    },
    onAdvStarted () {
      this.togglePrerollPlaying(true)
    },
    onPrerollCompleted () {
      this.togglePrerollPlaying(false)
      this.skinLoaderInitializer()
    },
    skinLoaderInitializer () {
      if (!this.firstCycle) {
        // console.log('not first cycle')
        return
      }
      // console.log('first cycle')
      const { source } = this.currentMedia
      if (this.showSkin) {
        loadSkinFromADServer(source)
      } else {
        if (ADV_RELOAD_TIME.SKIN > 0) {
          setTimeout(() => loadSkinFromADServer(source), ADV_RELOAD_TIME.SKIN)
        }
      }
      this.firstCycle = false
    }
  },

  beforeDestroy: function () {
    const { paused } = this.player
    if (!paused) {
      this.togglePlay()
    }
    // clearNielsenTracking()
    // this.destroyPlayer()
  },

  destroyed: function () { }
}
</script>
