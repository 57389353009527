<style lang="scss" scoped>
  @import './ChangeStreamTypeButton.scss';
</style>
<template>
  <div class="change-stream-type">
    <div v-if="relatedMedia !== null && mediaSource === MEDIA_SOURCE.LIVE && mediaType === MEDIA_TYPE.VIDEO" v-on:click="onClick"><i class="icon-tv"></i><span> Radiovisione</span></div>
    <div v-if="relatedMedia !== null && mediaSource === MEDIA_SOURCE.LIVE && mediaType === MEDIA_TYPE.AUDIO" v-on:click="onClick"><i class="icon-radio"></i><span> Radio</span></div>
  </div>
</template>
<script>
import { MEDIA_SOURCE, MEDIA_TYPE } from '../../lib/constants'
import { SWITCH_MEDIA } from '../../store/mutation-types'
export default {
  name: 'ChangeStreamTypeButton',
  created () {
    this.MEDIA_SOURCE = MEDIA_SOURCE
    this.MEDIA_TYPE = MEDIA_TYPE
  },
  computed: {
    relatedMedia () { return this.$store.state.mediaInfo.relatedMedia },
    mediaSource () { return this.relatedMedia !== null ? this.relatedMedia.source : null },
    mediaType () { return this.relatedMedia !== null ? this.relatedMedia.type : null }
  },
  methods: {
    onClick () {
      this.$store.commit(SWITCH_MEDIA)
    }
  }
}
</script>
