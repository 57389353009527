<style lang="scss" scoped>
  @import './PlayerControls.scss';
</style>
<template>
  <div class="player-controls">
    <i class="icon-play" v-if="!isPlaying" v-on:click="togglePlay"></i>
    <i class="icon-pause" v-if="isPlaying"  v-on:click="togglePlay"></i>
    <i class="icon-fullscreen" v-if="!isFullscreen" v-on:click="toggleFullscreen"></i>
    <i class="icon-fullscreen-exit" v-if="isFullscreen" v-on:click="toggleFullscreen"></i>
  </div>
</template>
<script>
import { TOGGLE_LAYOUT_PLAY_PAUSE, TOGGLE_LAYOUT_FULLSCREEN } from '../../store/mutation-types'
export default {
  name: 'PlayerControls',

  computed: {
    isPlaying () { return this.$store.state.playoutInfo.isPlaying },
    isFullscreen () { return this.$store.state.playoutInfo.isFullscreen }
  },

  methods: {
    togglePlay () { this.$store.commit(TOGGLE_LAYOUT_PLAY_PAUSE) },
    toggleFullscreen () { this.$store.commit(TOGGLE_LAYOUT_FULLSCREEN) }
  }
}
</script>
